import Footer from "../../Components/Footer/Footer";
import Navbar from "../../Components/Navbar/Navbar";
import SocialNavbar from "../../Components/SocialNavbar/SocialNavbar";
import "./AboutUs.css";


const AboutUs = () => {
    return (
        <div className="about-us-section">
            <SocialNavbar />
            <Navbar />
            <div className="about-us-container-main">
                <div className="about-us-img-container">
                    <h1>About Us</h1>
                </div>

                <div className="about-us-content">
                    <p>
                        Thisai (est. 2021) is a non-profitable and charitable trust (NGO) run by students based, in Chennai, Tamil Nadu. We, at Thisai strive to address and provide ample awareness for issues that are prevalent in our society. We focus mainly on EDUCATION and HEALTH with priority given to pleas of unnoticeable downtrodden people in our society. </p>
                    <p>
                        We also work to eradicate gender bias with precedence in women empowerment. We believe that, to see what is right and not doing it is a mistake in itself. We aim at making the world more inclusive to everyone without discrimination. So, join with us to shape a better world to live in where equality and justice are fairly served.
                    </p>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default AboutUs;