import { useState } from "react";
import { Link } from "react-router-dom";
import Logo from "./../../Asserts/logo1.jpg";
import Donate from "./../../Asserts/donate.jpg";
import "./Navbar.css";
import tick from "./../../Asserts/tick.gif"


const Navbar = () => {

    const [fullImgView, setFullImgView] = useState(false);
    const [mobileView, setMobileView] = useState(false);

    function CloseFullImg() {
        setFullImgView(false)
    }

    function OpenFullImg(e) {
        setFullImgView(true)
    }

    function handleMobileViewOpen() {
        setMobileView(true)
    }

    function handleMobileViewClose() {
        setMobileView(false)
    }
    return (
        <>
            <div className="full-img" id={fullImgView ? "fullImgBox" : ""}>
                <img src={Donate} alt="img1" id="fullImg" />
                <span onClick={CloseFullImg}><i class="fa-solid fa-xmark"></i></span>
            </div>
            {!mobileView && <div className="mobile-bar">
                <i class="fa-solid fa-bars" onClick={handleMobileViewOpen}></i>
            </div>}
            <div className="navbar-section">

                <div className={mobileView ? "navbar-container active" : "navbar-container"}>
                    <div className="logo-container">
                        <Link to="/"><img src={Logo} alt="Logo" ></img></Link>
                        <i class="fa-solid fa-xmark" onClick={handleMobileViewClose}></i>
                    </div>
                    <ul>
                        <Link to="/"><li className="underlineHover">Home</li></Link>
                        <Link to="/about-us"><li className="underlineHover">About Us</li></Link>
                        <Link to="/neet"><li className="underlineHover">NEET</li></Link>
                        <Link to="/projects"><li className="underlineHover">Projects</li></Link>
                        <Link to="/gallery"><li className="underlineHover">Gallery</li></Link>
                        <Link to="/contact-us"><li className="underlineHover">Contact Us</li></Link>
                        <li className="vertical-line"></li>
                        <p className="donate-button" onClick={(e) => { OpenFullImg() }}><span>DONATE</span></p>
                        <p className="verified top"><img src={tick} alt=""/> 12A</p>
                        <p className="verified"><img src={tick} alt=""/> 80G</p>
                    </ul>
                </div>
            </div>
        </>
    )
}

export default Navbar;