import "./Projects.css";
import { Masonry } from '@mui/lab';
import { Link } from "react-router-dom";
import Navbar from "../../Components/Navbar/Navbar";
import SocialNavbar from "../../Components/SocialNavbar/SocialNavbar";
import Footer from "../../Components/Footer/Footer";
import { useProjectDetails } from "../../ContextAPI/ProjectContext";


const Projects = () => {

    const { projectsData } = useProjectDetails();

    return (
        <div>
            <section className="blogs-navbar">
                <SocialNavbar />
                <Navbar />
            </section>
            <div className="project-img-container">
                <h1>Our Projects</h1>
            </div>


            <main id="site-main">


                <section id="posts">
                    <div className="blog-container">
                        <div className="grid">

                            <Masonry columns={3} spacing={2}>
                                {projectsData.map((data, index) => (
                                    <div className="grid-item" key={index} >
                                        <article className="article">
                                            <div className="blog-card">
                                                <div className="overflow-img">
                                                    <Link to={`/projects/${data.id}`}>
                                                        <img src={`/images/projects/${data.id}.jpg`} className="img-fluid" alt="" />
                                                    </Link>
                                                </div>
                                                <div className="blog-card-body text-center px-1" style={{ paddingTop: "15px" }}>
                                                    <Link to={`/projects/${data.id}`} className="text-title display-1 text-dark" style={{ textTransform: "uppercase" }}>
                                                        {data.title}
                                                    </Link>
                                                    <p className="secondary-title text-secondary display-3">
                                                        <span><i class="fa-solid fa-clock"></i> {data.date}</span>
                                                        <span className="center"> | </span>
                                                        <span><i class="fa-solid fa-location-dot"></i> {data.location}</span>
                                                    </p>
                                                </div>
                                            </div>
                                        </article>
                                    </div>))}


                            </Masonry>
                        </div>
                    </div>
                </section>
            </main>
            <section className="sectionFive"><Footer /></section>

        </div>
    )
}


export default Projects;