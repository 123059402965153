import { collection, query, getDocs, orderBy } from "firebase/firestore";
import { createContext, useContext, useEffect, useState } from "react";
import { db } from "../firebase-config";

const ProjectDetails = createContext();



export function ProjectDetailsProvider({ children }) {

    const [projectsData, setProjectsData] = useState([]);
    useEffect(() => {

        const getProjectsData = async () => {

            const docRef = query(collection(db, "Projects"), orderBy("timestamp"));
            const data = await getDocs(docRef)
            let project = data.docs.map((doc) => ({ ...doc.data(), id: doc.id }))
            setProjectsData(project.reverse())
        }
        getProjectsData()

    }, []);


    return <ProjectDetails.Provider value={{projectsData}}>{children}</ProjectDetails.Provider>

}


export function useProjectDetails() {
    return useContext(ProjectDetails);
}