import "./C404.css";


const F0F = () => {
    return (
        <div className="error">
            <h1>Error 404</h1>
            <p>page not found : (</p>
        </div>
    )
}


export default F0F;