import Navbar from "../../Components/Navbar/Navbar";
import SocialNavbar from "../../Components/SocialNavbar/SocialNavbar";
import "./Homepage.css";
import barath from "./../../Asserts/barath.jpg";
import praveen from "./../../Asserts/praveen.jpg";
import mani from "./../../Asserts/mani-new.jpg";
import { Link } from "react-router-dom";
import Footer from "../../Components/Footer/Footer";
import { Player } from '@lottiefiles/react-lottie-player';
import Marquee from "react-fast-marquee";
import { useProjectDetails } from "../../ContextAPI/ProjectContext";
import { useState, useEffect } from "react";
import context from 'require-context.macro';



const Homepage = () => {

    const { projectsData } = useProjectDetails();


    const [partners, setPartners] = useState([]);

    useEffect(() => {
        const imageFiles = context("./../../../public/images/partners", true, /\.(png|jpe?g|svg)$/);
        const partners = imageFiles.keys().map((filename) => imageFiles(filename));
        setPartners(partners);
    }, []);


    return (
        <div className="homepage-section">
            <SocialNavbar />
            <Navbar />
            <div className="homepage-container">
                <div className="dashboard-section">
                    <div className="carousel-display-space">
                        <div id="carouselExampleIndicators" class="carousel slide" data-pause={false}>
                            <ol class="carousel-indicators">
                                <li data-target="#carouselExampleIndicators" data-slide-to="0" class="active"></li>
                                <li data-target="#carouselExampleIndicators" data-slide-to="1"></li>
                                <li data-target="#carouselExampleIndicators" data-slide-to="2"></li>
                                <li data-target="#carouselExampleIndicators" data-slide-to="3"></li>
                                <li data-target="#carouselExampleIndicators" data-slide-to="4"></li>
                            </ol>
                            <div class="carousel-inner">
                                <div class="carousel-item active">
                                    <img class="d-block w-100" src="./images/home-carousel/1.jpg" alt=""/>
                                </div>
                                <div class="carousel-item">
                                    <img class="d-block w-100" src="./images/home-carousel/2.jpg" alt="" />
                                </div>
                                <div class="carousel-item">
                                    <img class="d-block w-100" src="./images/home-carousel/3.jpg" alt="" />
                                </div>
                                <div class="carousel-item">
                                    <img class="d-block w-100" src="./images/home-carousel/4.jpg" alt="" />
                                </div>
                                <div class="carousel-item">
                                    <img class="d-block w-100" src="./images/home-carousel/5.jpg" alt="" />
                                </div>
                            </div>
                            <a class="carousel-control-prev" href="#carouselExampleIndicators" role="button" data-slide="prev">
                                <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                                <span class="sr-only">Previous</span>
                            </a>
                            <a class="carousel-control-next" href="#carouselExampleIndicators" role="button" data-slide="next">
                                <span class="carousel-control-next-icon" aria-hidden="true"></span>
                                <span class="sr-only">Next</span>
                            </a>
                        </div>
                    </div>
                </div>
                <div className="home-title-card">
                    <h1>Welcome to</h1>
                    <h2>Thisai Charitable Trust</h2>
                    <p>
                        Thisai (est. 2021) is a non-profitable and charitable trust (NGO) run by students based,
                        in Chennai, Tamil Nadu. We, at Thisai strive to address and provide ample
                        awareness for issues that are prevalent in our society. We focus mainly on EDUCATION and HEALTH
                        with priority given to pleas of unnoticeable downtrodden people in our society.
                    </p>
                </div>
                <div className="read-more-btn">
                    <a href="/about-us" className="thisai-btn"><span>Read More</span></a>
                </div>
            </div>
            <div className="course-section">
                <div className="course-container">
                    <div className="course-tile-container">
                        <h1>Our Latest</h1>
                        <h2>Projects</h2>
                    </div>
                    <div className="course-card">
                        <ul class="cards">
                            <li>
                                <Link to={`/projects/${projectsData[0].id}`} class="card">
                                    <img src={`/images/projects/${projectsData[0].id}.jpg`} class="card__image" alt="" />
                                    <div class="card__overlay">
                                        <div class="card__header">
                                            <div class="card__header-text">
                                                <h3 class="card__title">{projectsData[0].title}</h3>
                                            </div>
                                        </div>
                                        <p class="card__description">{projectsData[0].bio}</p>
                                    </div>
                                </Link>
                            </li>
                            <li>
                                <Link to={`/projects/${projectsData[1].id}`} class="card">
                                    <img src={`/images/projects/${projectsData[1].id}.jpg`} class="card__image" alt="" />
                                    <div class="card__overlay">
                                        <div class="card__header">
                                            <div class="card__header-text">
                                                <h3 class="card__title">{projectsData[1].title}</h3>
                                            </div>
                                        </div>
                                        <p class="card__description">{projectsData[1].bio}</p>
                                    </div>
                                </Link>
                            </li>
                            <li>
                                <Link to={`/projects/${projectsData[2].id}`} class="card">
                                    <img src={`/images/projects/${projectsData[2].id}.jpg`} class="card__image" alt="" />
                                    <div class="card__overlay">
                                        <div class="card__header">
                                            <div class="card__header-text">
                                                <h3 class="card__title">{projectsData[2].title}</h3>
                                            </div>
                                        </div>
                                        <p class="card__description">{projectsData[2].bio}</p>
                                    </div>
                                </Link>
                            </li>
                        </ul>
                    </div>
                    <div>
                        <a href="/projects" className="thisai-btn"><span>See all other Projects</span></a>
                    </div>
                </div>
            </div>
            <div>
                <div className="home-title-card-founder">
                    <h1>The Vision</h1>
                    <h2>FOUNDER'S NOTE</h2>
                </div>
                <div className="founder-note-container">
                    <div className="fonder-content-container">
                        <div className="founder-image-wrapper left">
                            <img src={barath} alt=""></img>
                        </div>
                        <div class="container">
                            <div class="testimonial-box">
                                <div class="testimonial">
                                    <i class="fas fa-quote-right"></i>
                                    <span class="testimonial-text">
                                        During our Childhood our parents would have told Helping others is a
                                        good habit. But a very bad habit is that we all forget it when we
                                        grow or the same parents would also not do it. In this busy world,
                                        we don't have time to look at our surroundings. But if we look at
                                        our surrounding many things are changing and moving towards worse
                                        and alarming situation. We humans are responsible for this. More
                                        than needs, we start to become greedy. It is our responsibility to
                                        stop all this and make situations right. I strongly believe to see
                                        what is right and not doing it is a mistake in itself. So, through
                                        this Thisai Charitable Trust, I believe I can make things right as
                                        far as I can by joining with all likely minded people. Faced many
                                        criticisms and worse conditions but I strongly believe that at any
                                        situation my social activities never stops. Being a reason for
                                        someone's smile is the best feeling in the world. Want to feel
                                        this amazing feeling come just try with me.
                                    </span>
                                    <h1>Barath.C.Thaamira <b>|</b> Managing Trustee</h1>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="fonder-content-container mid">
                        <div class="container">
                            <div class="testimonial-box">
                                <div class="testimonial">
                                    <i class="fas fa-quote-right"></i>
                                    <span class="testimonial-text">
                                        We are all impermanent in this Planet; we are just a
                                        visitor for few years. So, why don’t we do something remarkable
                                        before leaving this planet? Even a small effort will make a
                                        significant difference in the society for giving something better
                                        for future generation. We came into this world with nothing in
                                        our hands and when we leave this planet, we take nothing with us. So,
                                        when we are here, we must do something for the development of the
                                        society. Social work is the only way which can change important
                                        things. Everybody thinks about paradise as their destination but
                                        to reach there we must do good works and to be an ideal man,
                                        one need to work for the betterment of others. Thisai is not just a
                                        foundation for me. It is a way of expressing my gratitude to the
                                        society that has given me so much love and respect.
                                        I have always felt that it is my duty to give back to society.
                                    </span>
                                    <a href="http://www.linkedin.com/in/praveen-kumar-thisai" target="_blank" rel="noreferrer"><h1>Praveen Kumar <b>|</b> Deputy Managing Trustee</h1></a>
                                </div>
                            </div>
                        </div>
                        <div className="founder-image-wrapper right">
                            <img src={praveen} alt=""></img>
                        </div>
                    </div>
                    <div className="fonder-content-container left">
                        <div className="founder-image-wrapper">
                            <img src={mani} alt=""></img>
                        </div>
                        <div class="container">
                            <div class="testimonial-box">
                                <div class="testimonial">
                                    <i class="fas fa-quote-right"></i>
                                    <span class="testimonial-text">
                                        I always wanted to do something for this society ,
                                        and when Barath brought the idea of Thisai Charitable Trust
                                        to me I was so excited. Through this trust I believe I can
                                        do many useful things rather than wasting money and time in
                                        unnecessary things. Moreover Thisai Charitable Trust makes me
                                        a responsible person for this country . Rather than searching
                                        for blessings in temples and spending so much of money there
                                        I believe helping others and their blessings will be a great
                                        thing to you. Hope I continue doing this as far as I can.
                                    </span>
                                    <h1>Manikandasudhan <b>|</b> Treasurer</h1>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="partner-section">
                <div className="partner-container">
                    <div className="partner-tile-container">
                        <h1>Our Proud</h1>
                        <h2>Partners</h2>
                    </div>
                </div>
                <div className="partner-logo-container">
                    <Marquee className="partner-logo-container">

                        {partners.map((image, index) => (
                            <div className="partner-logo" key={index}>
                                <img src={image} alt="" />
                            </div>
                        ))}

                    </Marquee>
                </div>
            </div>
            <div className="join-us-section-home">
                <div className="join-us-container">
                    <div className="join-us-wrapper">
                        <div className="join-us-content-card">
                            <h1 style={{fontSize:"30px"}}><span className="join-cmt">Want to change the world and do something for the society</span> <br></br>Come Join hands with us</h1>
                            <p>Become a volunteer in our NGO and you will get lots of benefits. You will develop many skills such as leadership skills , communication skills etc. You will be provided with a certificate which will be a added benefits in your resume. Till a lot more is there to explore . Click the link below to join our family</p>
                            <a href="https://form.jotform.com/222865579579478" target="_blank" rel="noreferrer"><span className="join-now">👉</span> <span>JOIN US NOW</span></a>
                        </div>
                        <div className="join-us-img-wrapper">
                            <Player
                                autoplay
                                loop
                                src="https://assets6.lottiefiles.com/packages/lf20_4djadzzo.json"
                                style={{ height: '100%', width: '100%' }}
                            >
                            </Player>
                        </div>
                    </div>
                </div>
            </div>




            <Footer />

        </div>
    )
}


export default Homepage;