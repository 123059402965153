import { useParams } from "react-router-dom";
import Footer from "../../Components/Footer/Footer";
import Navbar from "../../Components/Navbar/Navbar";
import SocialNavbar from "../../Components/SocialNavbar/SocialNavbar";
import { useProjectDetails } from "../../ContextAPI/ProjectContext";
import "./Project.css";
import { Navigate } from "react-router-dom";


const Project = () => {

    const { projectID } = useParams();
    const { projectsData } = useProjectDetails();



    if (projectsData.filter(data => data.id === projectID).length) {
        return (
            <div>   
                <SocialNavbar />
                <Navbar />

                <div className="project-section">
                    <div className="project-content">
                        <div className="blog-title">
                            <h1 style={{ textTransform: "uppercase" }}>{projectsData.filter(data => data.id === projectID)[0].title}</h1>
                        </div>
                        <div className="blog-sub-title">
                            <p><span style={{paddingRight:"5px"}}><i class="fa-solid fa-clock"></i></span> {projectsData.filter(data => data.id === projectID)[0].date}</p>
                            <p className="center">|</p>
                            <p><span style={{paddingRight:"5px"}}><i class="fa-solid fa-location-dot"></i></span> {projectsData.filter(data => data.id === projectID)[0].location}</p>
                        </div>
                        <div id="thumbnail" className="thumbnail mt-3"> <img src={`/images/projects/${projectsData.filter(data => data.id === projectID)[0].id}.jpg`} className="thumbnail" alt="" /></div>
                        <div className="blog-content-container">
                            {projectsData.filter(data => data.id === projectID)[0].body.map((items, index) => (
                                <p key={index}>{items}</p>
                            ))}
                        </div>
                    </div>
                </div>

                <Footer />


            </div>


        )

    } else {
        return <Navigate to="/error" replace={true} />
    }
}
export default Project;