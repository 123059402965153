import Navbar from "../../Components/Navbar/Navbar";
import SocialNavbar from "../../Components/SocialNavbar/SocialNavbar";
import Footer from "../../Components/Footer/Footer";
import "./Gallery.css";
import { useState, useEffect } from "react";
import context from 'require-context.macro';
import loader from "./../../Asserts/loader.gif";


const Gallery = () => {

    const [images, setImages] = useState([]);

    useEffect(() => {
        const imageFiles = context("./../../../public/images/gallery", true, /\.(png|jpe?g|svg)$/);

        const images = imageFiles.keys().map((filename) => imageFiles(filename));
        setImages(images.reverse());
    }, []);


    const [fullImgView, setFullImgView] = useState(false);
    const [imgSrc, setImgSrc] = useState("");

    function CloseFullImg() {
        setFullImgView(false)
    }

    function OpenFullImg(e) {
        setFullImgView(true)
        setImgSrc(e.target.src)
    }
    return (
        <>
            {images.length === 0 ? (
                <div className="loaderIcon">
                    <img src={loader} alt="Please Wait..." ></img>
                </div>
            ) :
                <div className="gallery-section">
                    <SocialNavbar />
                    <Navbar />

                    <div className="gallery-img-container">
                        <h1>Gallery</h1>
                    </div>

                    <div className="full-img" id={fullImgView ? "fullImgBox" : ""}>
                        <img src={imgSrc} alt="img1" id="fullImg" />
                        <span onClick={CloseFullImg}><i class="fa-solid fa-xmark"></i></span>
                    </div>

                    <div className="img-gallery">
                        {images.map(data => (
                            <div className="gal-img-wrapper">
                                <img src={data} onClick={(e) => { OpenFullImg(e) }} alt="" />
                            </div>
                        ))}


                    </div>

                    <Footer />

                </div>}
        </>
    )
}


export default Gallery;