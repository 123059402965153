import { Link } from "react-router-dom";
import "./SocialNavbar.css";


const SocialNavbar = () => {
    return (
        <div className="social-navbar-section">
            <div className="social-navbar-conatiner">
                <div className="welcome-container">
                    <i class="fa-solid fa-hand-holding-heart"></i>
                    <Link to="/">Welcome to Thisai Charitable Trust</Link>
                </div>
                <div className="nav-contact">
                    <div className="nav-mobile">
                        <i class="fa-solid fa-phone-volume"></i>
                        <p><a href="tel:+93600 16260">+91 93600 16260</a></p>
                    </div>
                    <div className="nav-email">
                        <i class="fa-solid fa-envelope"></i>
                        <p><a href="mailto:info@thisaicharitabletrust.com">info@thisaicharitabletrust.com</a></p>
                    </div>
                </div>
                <div className="follow-us-container">
                    <p>FOLLOW US ON </p>
                    <a rel="noreferrer" target="_blank" href="https://www.facebook.com/thisaicharitabletrust"><i class="fa-brands fa-facebook-f"></i></a>
                    <a rel="noreferrer" target="_blank" href="https://www.youtube.com/channel/UCf6vTGVPZy0y8bTFeh825BA "><i class="fa-brands fa-youtube"></i></a>
                    <a rel="noreferrer" target="_blank" href="https://twitter.com/tct_2021"><i class="fa-brands fa-twitter"></i></a>
                    <a rel="noreferrer" target="_blank" href="https://www.instagram.com/thisai_official/"><i class="fa-brands fa-instagram"></i></a>
                    <a rel="noreferrer" target="_blank" href="https://www.linkedin.com/company/90519377/admin/"><i class="fa-brands fa-linkedin-in"></i></a>
                </div>
            </div>
        </div>
    )
}

export default SocialNavbar;