import Navbar from "../../Components/Navbar/Navbar";
import Footer from "../../Components/Footer/Footer";
import SocialNavbar from "../../Components/SocialNavbar/SocialNavbar";
import "./NEET.css";
import doctor from "./../../Asserts/coaching/doctor.png";
import { Player } from '@lottiefiles/react-lottie-player';
const NEET = () => {

    return (
        <div>
            <SocialNavbar />
            <Navbar />
            <div className="homepage-container">
                <div className="dashboard-section">
                    <div className="carousel-display-space">
                        <div id="carouselExampleIndicators" class="carousel slide">
                            <ol class="carousel-indicators">
                                <li data-target="#carouselExampleIndicators" data-slide-to="0" class="active"></li>
                                <li data-target="#carouselExampleIndicators" data-slide-to="1"></li>
                                <li data-target="#carouselExampleIndicators" data-slide-to="2"></li>
                                <li data-target="#carouselExampleIndicators" data-slide-to="3"></li>
                                <li data-target="#carouselExampleIndicators" data-slide-to="4"></li>
                            </ol>
                            <div class="carousel-inner">
                                <div class="carousel-item active">
                                    <img class="d-block w-100" src="/images/neet-carousel/1.jpg" alt="" />
                                </div>
                                <div class="carousel-item">
                                    <img class="d-block w-100" src="/images/neet-carousel/2.jpg" alt="" />
                                </div>
                                <div class="carousel-item">
                                    <img class="d-block w-100" src="/images/neet-carousel/3.jpg" alt="" />
                                </div>
                                <div class="carousel-item">
                                    <img class="d-block w-100" src="/images/neet-carousel/4.jpg" alt="" />
                                </div>
                                <div class="carousel-item">
                                    <img class="d-block w-100" src="/images/neet-carousel/5.jpg" alt="" />
                                </div>
                            </div>
                            <a class="carousel-control-prev" href="#carouselExampleIndicators" role="button" data-slide="prev">
                                <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                                <span class="sr-only">Previous</span>
                            </a>
                            <a class="carousel-control-next" href="#carouselExampleIndicators" role="button" data-slide="next">
                                <span class="carousel-control-next-icon" aria-hidden="true"></span>
                                <span class="sr-only">Next</span>
                            </a>
                        </div>
                    </div>
                </div>
                <div className="coaching-section">
                    <div className="coaching-wrapper">
                        <div className="coaching-container">
                            <div className="doc-img-wrapper">
                                <img src={doctor} alt="doctor"></img>
                            </div>

                            <div className="coaching-details">
                                <h1>FREE NEET <br></br> COACHING</h1>
                                <h2>For Govt. And Govt. Aided English Medium School Students</h2>
                                <h4>COME AND JOIN HANDS WITH US</h4>
                            </div>
                        </div>
                    </div>
                    <div className="join-us-wrapper message-box">
                        <div className="join-us-content-card">
                            <h1><span className="join-cmt">Join the Community</span> Now as a Student</h1>
                            <p>If you are an aspiring government or government aided school student who wish to become a doctor, then apply here. We are here to guide you. You will be in safe hands and perfect guidance.</p>
                            <p>Join hands with us and become a Medico. We provide you the best free NEET coaching and try our level best to provide a perfect guidance for you.</p>
                            <a href="https://forms.gle/cRrf5gzHi5C5h36V6" target="_blank" rel="noreferrer" ><span className="join-now">👉</span> <span>CLICK , FILL THE FORM AND JOIN HANDS WITH US </span></a>
                        </div>
                        <div className="join-us-img-wrapper">
                            <Player
                                autoplay
                                loop
                                src="https://assets1.lottiefiles.com/packages/lf20_ei2gf306.json"
                                style={{ height: '100%', width: '100%' }}
                            >
                            </Player>
                        </div>
                    </div>

                </div>

            </div>

            <div className="about-us-section">
                <div className="about-us-container-neet">
                    <div className="about-us-intro-card">
                        <div className="course-tile-container neet">
                            <h1>Who We Are</h1>
                            <h2>ABOUT US</h2>
                        </div>
                        <p className="abt-us-content">
                            We Thisai Charitable Trust mainly focus on Health and Education. This is our new venture i.e., providing free neet coaching to Government and Government aided school students. Thisai always wishes that all the facilities must also be provided to lower class and poor people. Now-a-days Neet coaching is charged for minimum of Rs.50k approximately. We wanted this Neet coaching to reach the students of Government and Government aided schools. So, we started this free neet coaching initiative. We now just started in Chennai and hoping to spread hands to other districts in future.
                        </p>
                    </div>
                </div>
            </div>

            <div className="what-vdo-section">
                <div className="what-vdo-wrapper">
                    <div className="what-vdo-container">
                        <div className="service-details">
                            <div className="course-tile-container neet wvd">
                                <h1>What We Do</h1>
                                <h2>SERVICES</h2>
                            </div>
                            <h2 className="wvd-subtitle">The Program Aims at Providing</h2>
                            <div className="service-content">
                                <div className="service-content-container">
                                    <i class="fa-solid fa-earth-americas"></i>
                                    <h4>Online Lectures</h4>
                                </div>
                                <div className="service-content-container">
                                    <i class="fa-regular fa-file-word"></i>
                                    <h4>Weekly Assignments</h4>
                                </div>
                                <div className="service-content-container">
                                    <i class="fa-regular fa-calendar"></i>
                                    <h4>Periodical Exams</h4>
                                </div>
                                <div className="service-content-container">
                                    <i class="fa-regular fa-note-sticky"></i>
                                    <h4>Doubt clearing sessions in weekends</h4>
                                </div>
                                <div className="service-content-container">
                                    <i class="fa-regular fa-file-video"></i>
                                    <h4>Recorded conceptual videos</h4>
                                </div>
                                <div className="service-content-container">
                                    <i class="fa-solid fa-lightbulb"></i>
                                    <h4>Highly conceptualized on NEET pattern</h4>
                                </div>
                            </div>
                        </div>

                        <div className="service-img-wrapper">
                            <Player
                                autoplay
                                loop
                                src="https://assets9.lottiefiles.com/packages/lf20_fq7pwzey.json"
                                style={{ height: '100%', width: '100%' }}
                            >
                            </Player>
                        </div>
                    </div>
                </div>
            </div>


            <div className="join-us-section">
                <div className="join-us-container">
                    <div className="join-us-wrapper">
                        <div className="join-us-content-card">
                            <h1><span className="join-cmt">Join the Community</span> Now as a Mentor</h1>
                            <p>The team would like to collaborate with young dynamic volunteers who are looking for a small opportunity to support the young budding medical community.</p>
                            <p>Your contribution in terms of being a mentor/ providing reference books/ any other supportive tools could lighten the lives of many aspiring future doctors.</p>
                            <a href="https://forms.gle/Y9LHo4DXC4Ezbw4z6" target="_blank" rel="noreferrer"><span className="join-now">👉</span> <span>JOIN US</span></a>
                        </div>
                        <div className="join-us-img-wrapper">
                            <Player
                                autoplay
                                loop
                                src="https://assets6.lottiefiles.com/packages/lf20_4djadzzo.json"
                                style={{ height: '100%', width: '100%' }}
                            >
                            </Player>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}


export default NEET;