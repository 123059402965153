import { useState, useEffect } from "react";
import { useProjectDetails } from "./ProjectContext";
import loader from "./../Asserts/loader.gif";


const Loader = ({ children }) => {
    const { projectsData } = useProjectDetails();
    const [loaded, setLoaded] = useState(false);




    useEffect(() => {

        if (projectsData.length) {
            setLoaded(true)
        } else {
            setLoaded(false)
        }

    }, [projectsData]);





    if (loaded) {
        return children
    } else {
        return <div className="loaderIcon">
            <img src={loader} alt="Please Wait..." ></img>
        </div>

    }
}

export default Loader;