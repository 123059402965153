import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.css';
import Loader from './ContextAPI/Loader';
import { ProjectDetailsProvider } from './ContextAPI/ProjectContext';
import F0F from './Pages/404/404';
import AboutUs from './Pages/AboutUs/AboutUs';
import ContactUs from './Pages/ContactUs/ContactUs';
import Gallery from './Pages/Gallery/Gallery';
import Homepage from './Pages/Homepage/Homepage';
import NEET from './Pages/NEET/NEET';
import Project from './Pages/Projects/Project';
import Projects from './Pages/Projects/Projects';
import ScrollToTop from './ScrollTop';

function App() {
  return (
    <div>
      <ProjectDetailsProvider>
        <Loader>
          <BrowserRouter>
            <ScrollToTop />
            <Routes>
              <Route exact path="/" element={<Homepage />} />
              <Route exact path="/about-us" element={<AboutUs />} />
              <Route exact path="/neet" element={<NEET />} />
              <Route exact path="/projects" element={<Projects />} />
              <Route exact path="/projects/:projectID" element={<Project />} />
              <Route exact path="/gallery" element={<Gallery />} />
              <Route exact path="/contact-us" element={<ContactUs />} />
              <Route exact path="*" element={<F0F />} />
            </Routes>
          </BrowserRouter>
        </Loader>
      </ProjectDetailsProvider>
    </div>
  );
}

export default App;
