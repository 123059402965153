import { useEffect, useState } from "react";
import emailjs from '@emailjs/browser';
import toast from 'react-hot-toast';


const useContactForm = (Validate) => {

    const [errors, setErrors] = useState({});
    const [isSubmit, setIsSubmit] = useState(false);
    const [loader, setLoader] = useState(false);
    const date = new Date();

    const notify = () => toast.success('Submitted Successfully!')

    


    const [mailValues, setMailValues] = useState({

        name: "",
        email: "",
        timestamp: "",
        subject: "",
        body: "",
        ticket_id: "",
    })

    const sendEmail = (emailValues) => {
        emailjs.send(process.env.REACT_APP_emailService, process.env.REACT_APP_emailTemplate, emailValues, process.env.REACT_APP_emailAPIPublicKey)
            .then((result) => {
                setIsSubmit(false)
                setLoader(false)
                setMailValues({

                    name: "",
                    email: "",
                    timestamp: "",
                    subject: "",
                    body: "",
                    ticket_id: "",
                })
                notify()
            }, (error) => {
                console.log(error.text);
            });
    };


    const handleChange = (event) => {
        const { name, value } = event.target;
        setMailValues((preValues) => {
            return {
                ...preValues,
                [name]: value,
            }
        })

    }




    

    function generateID() {
        var possible = "0123456789";
        let text ="";
        for (var i = 0; i < 5; i++)
            text += possible.charAt(Math.floor(Math.random() * possible.length));
        return text;
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        setErrors(Validate(mailValues));
        setIsSubmit(true)
    }

    useEffect(() => {

        const createUser = async () => {

            mailValues.ticket_id = generateID();
            mailValues.timestamp = date;
            

            try {

                sendEmail(mailValues)



            } catch (error) {
                console.log(error.message)
            }

        }

        if (Object.keys(errors).length === 0 && isSubmit) {
            createUser();
            setLoader(true)
        }

    }, [errors, isSubmit, mailValues])// eslint-disable-line react-hooks/exhaustive-deps





    return { handleChange, handleSubmit, mailValues, errors, loader }
}

export default useContactForm;