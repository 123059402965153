import "./Footer.css";
import Logo from "./../../Asserts/logo1.jpg";
import { Link } from "react-router-dom";

const Footer = () => {
    const d = new Date();
    return (
        <div className="footer-section">
            <div className="footer-container">
                <div className="footer-section-1">
                    <div className="footer-about-us-container">
                        <div className="footer-about-us">
                            <div className="footer-logo-wrapper"><img src={Logo} alt="Logo"></img></div>
                        </div>
                    </div>
                    <div className="footer-contact-us">
                        <h2>CONTACT INFO</h2>
                        <p className="address">
                            Plot No.64, Shrudi Sai Nagar, Agaram Main Road,
                            Madambakkam, Chennai - 600 126, Tamil Nadu,
                            India.
                        </p>
                        <p className="cont-details act"><span>Phone:</span><a href="tel:+91 9360016260">+91 93600 16260</a></p>
                        <p className="cont-details"><span>Phone:</span><a href="tel:+91 8122598351">+91 81225 98351</a></p>
                        <p className="cont-details act"><span>Email:</span><a href="mailto:info@thisaicharitabletrust.com">info@thisaicharitabletrust.com</a></p>
                        <p className="cont-details"><span>Email:</span><a href="mailto:thisaiofficial@gmail.com">thisaiofficial@gmail.com</a></p>
                        <div className="social-nav-links">
                            <a rel="noreferrer" target="_blank" href="https://www.facebook.com/thisaicharitabletrust"><i class="fa-brands fa-facebook-f"></i></a>
                            <a rel="noreferrer" target="_blank" href="https://www.youtube.com/channel/UCf6vTGVPZy0y8bTFeh825BA "><i class="fa-brands fa-youtube"></i></a>
                            <a rel="noreferrer" target="_blank" href="https://twitter.com/tct_2021"><i class="fa-brands fa-twitter"></i></a>
                            <a rel="noreferrer" target="_blank" href="https://www.instagram.com/thisai_official/"><i class="fa-brands fa-instagram"></i></a>
                            <a rel="noreferrer" target="_blank" href="https://www.linkedin.com/company/90519377/admin/"><i class="fa-brands fa-linkedin-in"></i></a>
                        </div>
                    </div>
                </div>
                <hr></hr>
                <div className="footer-section-2">
                    <div className="copy-rights">
                        <p><b>©{d.getFullYear()} Thisai Charitable Trust.</b><br></br>Designed and Developed by <span><a target="_blank" rel="noreferrer" href="https://www.linkedin.com/in/anirudhadithya/">Anirudh Adithya</a></span><br /></p>
                    </div>
                    <div className="footer-nav-links">
                        <ul>
                            <Link to="/"><li><span className="underlineHover">HOME</span></li></Link>
                            <Link to="/about-us"><li><span className="underlineHover">ABOUT US</span></li></Link>
                            <Link to="/neet"><li><span className="underlineHover">NEET</span></li></Link>
                            <Link to="/projects"><li><span className="underlineHover">PROJECTS</span></li></Link>
                            <Link to="/gallery"><li><span className="underlineHover">GALLERY</span></li></Link>
                            <Link to="/contact-us"><li><span className="underlineHover">CONTACT US</span></li></Link>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    )
}


export default Footer;