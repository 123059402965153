import Footer from "../../Components/Footer/Footer";
import Navbar from "../../Components/Navbar/Navbar";
import SocialNavbar from "../../Components/SocialNavbar/SocialNavbar";
import useContactForm from "../../Hooks/ValidateContactForm";
import "./ContactUs.css";
import ValidateContactValues from "../../Utils/ValidateContactValues";
import { Toaster } from 'react-hot-toast';

const ContactUs = () => {

    const { handleChange, handleSubmit, mailValues, errors, loader } = useContactForm(ValidateContactValues)
        
    return (
        <div>
            <SocialNavbar />
            <Navbar />

            <Toaster
                position="top-left"
                reverseOrder={false}
            />
            <div>
                <div style={{ width: "100%" }}>
                    <iframe
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1944.6873648232731!2d80.15380645460903!3d12.883608890563062!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a52593ab3bd887b%3A0x557852627c25b43e!2sThisai%20Charitable%20Trust!5e0!3m2!1sen!2sin!4v1675862737670!5m2!1sen!2sin"
                        width="100%"
                        height="500"
                        frameborder="0"
                        scrolling="no"
                        marginheight="0"
                        marginwidth="0"
                        title="Thisai Charitable Trust">
                    </iframe>
                </div>
                <div className="contactInformation">
                    <div className='contactMainContainer'>
                        <h1>Contact Information</h1>
                        <table className='contactMainTable'>
                            <tbody>
                                <tr>
                                    <td valign="top" className='contactTableHeading mainAddress'>
                                        <div className='india-Office'>
                                            <h4>CHENNAI</h4>
                                            <p>Tamilnadu</p>
                                        </div>
                                    </td>
                                    <td valign="top">
                                        <table className='contactSubTable'>
                                            <tbody>
                                                <tr>
                                                    <td className='contactTableSubHeading' valign="top"><h5>ADDRESS</h5></td>
                                                    <td><p><a target="_blank" rel="noreferrer" href={"https://www.google.com/maps/place/Thisai+Charitable+Trust/@12.8832546,80.1520657,17z/data=!3m1!4b1!4m6!3m5!1s0x3a52593ab3bd887b:0x557852627c25b43e!8m2!3d12.8832546!4d80.1542544!16s%2Fg%2F11pzkd0vrf"}>
                                                        Thisai Charitable Trust, Plot No.64, <br></br>
                                                        Shrudi Sai Nagar, Agaram Main Road,<br /> Madambakkam, Chennai - 600 126, Tamil Nadu, India<br></br>
                                                    </a>
                                                    </p></td>
                                                </tr>
                                                <tr>
                                                    <td className='contactTableSubHeading' valign="top"><h5>PHONE</h5></td>
                                                    <td><p><a href="tel:+919360016260">+91 93600 16260</a><br></br>
                                                        <a href="tel:+918122598351">+91 81225 98351</a></p></td>
                                                </tr>
                                                <tr>
                                                    <td className='contactTableSubHeading' valign="top"><h5>EMAIL</h5></td>
                                                    <td><p><a href="mailto:info@thisaicharitabletrust.com">info@thisaicharitabletrust.com</a><br></br><a href="mailto:thisaiofficial@gmail.com">thisaiofficial@gmail.com</a></p></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </td>
                                </tr>

                            </tbody>
                        </table>
                        <div className='getInTouchSection'>
                            <div className='getInTouchSectionContainer'>
                                <h1>Get In Touch</h1>
                                <div className='getInTouchBody'>
                                    <div className='contact-input-pair'>
                                        <div className="floating-label-group">
                                            <input onChange={handleChange} name="name" value={mailValues.name} type="text" className="form-control" required />
                                            <label className="floating-label">Name</label>
                                            {errors.name && <p>*{errors.name}</p>}
                                        </div>
                                        <div className="floating-label-group-space">
                                        </div>
                                        <div className="floating-label-group">
                                            <input onChange={handleChange} name="email" value={mailValues.email} type="text" className="form-control" required />
                                            <label className="floating-label">Email</label>
                                            {errors.email && <p>*{errors.email}</p>}
                                        </div>
                                    </div>
                                    <div className="floating-label-group">
                                        <input onChange={handleChange} name="subject" value={mailValues.subject} type="text" className="form-control subject" required />
                                        <label className="floating-label">Subject</label>
                                        {errors.subject && <p>*{errors.subject}</p>}
                                    </div>
                                    <div class="textAreaContactSection">
                                        <textarea onChange={handleChange} value={mailValues.body} name="body" id="textarea" placeholder="message"></textarea>
                                        <label for="textarea">Message</label>
                                    </div>
                                    {errors.body && <p className="special-p">*{errors.body}</p>}
                                    <div className='floating-label-group-button'>
                                        {loader ? <button style={{ cursor: "wait", backgroundColor: "#4b4a4c" }}>Please Wait...</button> : <button onClick={handleSubmit}>Send Message</button>}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}


export default ContactUs;