export default function ValidateContactValues(values) {

    let errors = {};


    if (!values.name.trim()) {
        errors.name = "Name Required";
    }
    if (!values.email.trim()) {
        errors.email = "Email Required"
    } 
    //eslint-disable-next-line
    else if (!(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(values.email))) {
        errors.email = "Invalid Email"
    }
    if (!values.subject.trim()) {
        errors.subject = "Subject Required"
    }
    if (!values.body.trim()) {
        errors.body = "Message Required"
    } else if (values.body.length < 20) {
        errors.body = "Message should have atleast 20 Characters"
    }

    return errors;
}